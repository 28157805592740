import React, {useState} from 'react'
import styles from './reset_password.module.scss'
import {Card, TextField, Button, Checkbox, Snackbar, SnackbarContent } from '@material-ui/core'
import { createMuiTheme } from "@material-ui/core/styles";
import config from '../../config'
import { ThemeProvider } from "@material-ui/styles";
import {IoIosWarning, IoIosCheckmarkCircle} from "react-icons/io";
import {Link, navigate} from 'gatsby'
import Lottie from 'lottie-react-web'
import animation from './../../animations/loading-cowork2.json'

const boxStyle = {
	container: {     
		//padding: '2em',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.1) !important',
		//border: '1px solid #EAEAF2',
		borderRadius: '1em !important',
		fontWeight: '600',
		color: '#23263E',
	  },   
	  box: {
		pointerEvents: 'none',
		margin: '0px',
		padding: '0px 0px 0px 90px',
		left: '0px',
		top: '-5px',
		borderStyle: 'solid',
		position: 'absolute',
		borderWidth: '1px',
		bottom: '0',
		borderRadius: '4px',
		right: '0',
	  }
  };




const ForgetCard = () => {
	
	const [loadingRequest, setLoadingRequest] = useState(false)
	const [openSnack, setOpenSnack] = useState(false)
	const [errorMessage, setErrorMessage] = useState({
		'message': '',
		'color': ''
	})

	const handleCheck = (event,name) => {		
		
		let passw1 = document.getElementById("password1").value
		let passw2 = document.getElementById("password2").value
		
		setUserData({...userData, [name]: event.target.value})
		if (passw1 == passw2){
			if (passw1.length >= 8) {
			setErrorMessage({'message':'Las contraseñas cumplen con los requisitos.','color':'#43a047'})
			setOpenSnack(true)
			takeToken()
			}
			else {
				setErrorMessage({'message':'Las contraseñas deben ser mayor o igual a 8 caracteres.','color':'#d32f2f'})
			}
		}	
		else {
			setErrorMessage({'message':'Las contraseñas deben ser iguales.','color':'#d32f2f'})
			setOpenSnack(true)
			//setUserData({...userData, [name]: ''});
		}
	}
	const takeToken = () => {
		var url_string = window.location.href
		var url = new URL(url_string);
		var tokenValue = url.searchParams.get("token");
		setUserData({...userData, token: tokenValue})		
	}
	const [userData, setUserData] = useState({
		'password1': '',
		'password2': '',
		'token': ''
	})
	

	const handleSubmit = async() => {

		if(!validateValues(userData)){
			console.log("userData", userData)
			setLoadingRequest(true)
			try{
				const response = await fetch(`${config.server}/forget_password`,{
					method: 'DELETE',
					body: JSON.stringify({
						token_recovery_password: userData.token,
						password: userData.password1,
					}),
					headers: {
						'Content-Type': 'application/json'
					}
				})
				const statusRequest = await response.json()
				console.log(statusRequest)
				setLoadingRequest(false)
		
				if(statusRequest.info === "Se ha completado el proceso recuperacion de cuenta"){
					
					setOpenSnack(true)
					setLoadingRequest(true)
					setErrorMessage({'message': statusRequest.info,'color':'#43a047'})
					setTimeout(function(){ navigate('/login/'); }, 3000);
				}else {
					setErrorMessage({'message': statusRequest.info,'color':'#d32f2f'})
					setOpenSnack(true)		
				
				}
			}catch(e){
				console.log(e)
			}
		}
	}

	const validateValues = (userData) => {
		let flag = false
		let valuesData = Object.values(userData)

		for(let i = 0; i < valuesData.length; i++){
			if(/^\s*$/.test(valuesData[i])){
				flag = true;
				setErrorMessage({'message':'Por favor completa el formulario.','color':'#d32f2f'});
				setOpenSnack(true);
			}
		}

		return flag
	}

	const SnackBarWrapper = ({message}) => {
		return(
			<Snackbar
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'left',
				}}
				open={openSnack}
				autoHideDuration={6000}
				onClose={()=>setOpenSnack(!openSnack)}
			  >
				<SnackbarContent
					style={{backgroundColor: errorMessage.color}}
					  message={
						<span className={styles.errorMessage}>
							{
								errorMessage.color == "#d32f2f" ? (
									<IoIosWarning size={20} className={styles.iconError}/>
								): (
									errorMessage.color == "#43a047" ? (
										<IoIosCheckmarkCircle size={20} className={styles.iconError}/>
									): (
										<p>Nada</p>
									)
								)
							}
						    <span>{message}</span>
						</span>
					  }
					  onClose={()=>setOpenSnack(!openSnack)}
					  variant="error"
				/>
			</Snackbar>
		)
	}
	
	
	return(
		<Card className={styles.container}>
			<h2>Cambio de contraseña</h2>
			<ThemeProvider theme={theme} >
				<TextField
			        label="Contraseña"
				    id="password1"
					//className={styles.textField}
					style={boxStyle.box, boxStyle.container}
			        margin="normal"
					type="password"
			        variant="outlined"
					value={userData.password1}
					onChange={(e) => handleCheck(e, 'password1')}
		        />
				<TextField
			        label="Confirmacion Contraseña"
					id="password2"
					//className={styles.textField}
					style={boxStyle.box, boxStyle.container}
			        margin="normal"
					type="password"
					//value={userData.password2}
					onChange={(e) => handleCheck(e, 'password2')}
					variant="outlined"
			    />
			</ThemeProvider>
			<Button onClick={handleSubmit} className={styles.buttonLogin} variant="contained">Cambiar</Button>
			<SnackBarWrapper message={errorMessage.message}/>
				{
					loadingRequest ? (
						<div className='loottieAnimation'>
							<Lottie
								options={{
								  animationData: animation
								}}
								style={{
									position: 'fixed',
									left: '50%',
									zIndex: 1000,
									background: 'rgba(0,0,0,0.2)',
									transform: 'translateX(-50%) translateY(-50%)',
									top: '50%'
								}}
							  />
						</div>
					): (null)
				}
		</Card>
	)
}


const theme = createMuiTheme({
	overrides: {
      MuiOutlinedInput: {
          root: {
              position: 'relative',
			  color:'#000',
              '& $notchedOutline': {
                  borderColor: 'rgba(0,0,0,0.23)',
              },
              '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                  borderColor: 'rgba(0,0,0,0.23)',
                  // Reset on touch devices, it doesn't add specificity
                  '@media (hover: none)': {
                      borderColor: 'rgba(0,0,0,0.23)',
                  },
              },
              '&$focused $notchedOutline': {
                  borderColor: '#DE892A',
                  borderWidth: 1,
              },
          },
      },
      MuiFormLabel: {
          root: {
			  color:'rgba(0,0,0,0.54)',
              '&$focused': {
                  color: '#DE892A'
              }
          }
      }}
});


export default ForgetCard
