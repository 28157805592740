import React from 'react'
import styles from './styles/login.module.scss'
import HeaderBack from "../components/HeaderBack"
import Layout from "../components/Layout"
import {navigate} from 'gatsby'
import SEO from "../components/seo"
import {useSelector} from 'react-redux'
import ForgetCard from '../components/ResetPassword/index'
import './styles/lottie.css'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles(theme => ({
	root: {
	  flexGrow: 1,
	},
	paper: {
	  padding: theme.spacing(2),
	  textAlign: 'center',
	  color: theme.palette.text.secondary,
	},
  }));



const Login = () => {
	const isLogged = useSelector(state => state.user.isLogged)
	const classes = useStyles();

	if(isLogged){
		navigate('/forget')
	}
	return(
		<Layout>
			<SEO title="Olvide la Contraseña aaaaa" />
			<HeaderBack
				title="Cambio de Contraseña"
				description="Ingresa tu nueva contraseña!"
				image={require('./../images/ingresar.jpg')}
			/>
			<div className={classes.root}>
				<Grid container spacing={3}
				style = {{
					justifyContent: "center",
					display: "flex",
					marginTop: "1rem"
				}}>
					<Grid item xs={11} md = {5}>
						<ForgetCard/>
					</Grid>
				</Grid>
			</div>
			{/* <div className={styles.loginContainer}>
				<LoginCard/>
			</div> */}
		</Layout>
	)
}

export default Login